<template>
    <div class="container-fluid py-3">
      <div class="row justify-content-center">
        <div class="col-12">
          <p v-html="$t('betMsgEntry', {coin: bet})"></p>
          <hr>
          <b-form class="row" @submit.prevent="save()">
            <div class="form-group col-6 col-md-3">
              <label for="local">Local</label>
              <select name="local" id="local" class="form-control" v-model="position" v-validate="'required'" :state="validateState('local')">
                <option value="local">Local</option>
                <option value="visitor">{{ $t('visitor') }}</option>
              </select>
              <span class="error-inputs">{{ errors.first('local') }}</span>
            </div>
            <div class="form-group col-6 col-md-3">
              <label for="gain">{{ $t('gain') }}</label>
              <b-form-input v-model="bet" readonly id="gain"></b-form-input>
            </div>
            <div class="form-group col-6 col-md-3">
              <label for="date">{{ $t('date') }}</label>
              <b-form-input v-model="date" type="date" id="date" name="date" v-validate="'required'" :state="validateState('date')"></b-form-input>
              <span class="error-inputs">{{ errors.first('date') }}</span>
            </div>
            <div class="form-group col-6 col-md-3">
              <label for="time">{{ $t('time') }}</label>
              <b-form-input v-model="time" type="time" id="time" name="time" v-validate="'required'" :state="validateState('time')"></b-form-input>
              <span class="error-inputs">{{ errors.first('time') }}</span>
            </div>
            <div class="form-group col-12">
              <label for="comments">{{ $t('comments') }}</label>
              <b-form-textarea v-model="comments" id="date" name="comments" v-validate="'max:190'" :state="validateState('comments')"></b-form-textarea>
              <span class="error-inputs">{{ errors.first('comments') }}</span>
            </div>
            <div class="col-12">
              <b-button variant="primary" type="submit">{{ $t('save') }}</b-button>
              <b-button @click="$router.go(-1)" class="ml-2">{{ $t('cancel') }}</b-button>
            </div>
          </b-form>
        </div>
      </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import * as moment from 'moment'

export default {
  data () {
    return {
      match: {},
      bet: 0,
      comments: '',
      date: null,
      time: null,
      position: this.$route.params.position
    }
  },
  computed: {
    ...mapGetters([
      'console',
      'lang',
      'user',
      'isAuthenticated'
    ])
  },
  mounted () {
    this.fetchData()
  },
  methods: {
    fetchData () {
      const matchId = this.$route.params.matchId
      const path = `${this.lang}/console/${this.console}/shop/bets/vs/${matchId}/${this.position}/entry`
      this.$axios.get(path).then(response => {
        const data = response.data.data
        this.bet = data.bet
        this.date = data.date ? moment(data.date, 'DD-MM-YYYY').format('YYYY-MM-DD') : null
        this.time = data.time
      })
    },
    save () {
      this.$validator.validate().then(result => {
        if (result) {
          const payload = {
            date: this.date,
            time: this.time,
            position: this.position,
            comments: this.comments
          }
          const matchId = this.$route.params.matchId
          const path = `auth/${this.lang}/console/${this.console}/shop/bets/vs/${matchId}/${this.position}/save`
          this.$axios.post(path, payload).then((response) => {
            this.$toastr.success(response.data.message, window.TITLE_SUCCESS)
            this.$router.push({ name: 'Vs', params: { lang: this.lang, console: this.console } })
          })
        } else {
          this.showValidationsErrors(this.errors.items);
        }
      })
    }
  }
}
</script>
